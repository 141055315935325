import Link from 'gatsby-link'
import React from 'react'
import { HTMLContent } from '../../components/Content'
import { EventTemplate } from '../../templates/event-page'

const EventPagePreview = ({ events }) => {
  console.log({ events })

  return (
    <div className="container event-preview-container">
      {events
        .filter((event) => {
          return event.node.frontmatter.templateKey === 'event-page'
        })
        .map(({ node: event }) => (
          <div
            className="content event-preview"
            key={event.id}
            style={{
              border: '1px solid #eaecee',
              padding: '2em 10%',
            }}
          >
            <p>
              <Link className="has-text-primary" to={event.fields.slug}>
                {event.frontmatter.title}
              </Link>
              <span> &bull; </span>
              <small>{event.frontmatter.event_date}</small>
            </p>
            <p>
              {event.excerpt}
              <br />
              <br />
              <Link className="button is-small" to={event.fields.slug}>
                More Information →
              </Link>
            </p>
          </div>
        ))}
    </div>
  )
}

export default EventPagePreview
