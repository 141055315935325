import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import EventPreview from '../../cms/preview-templates/EventPagePreview'

export default class EventsPage extends Component {
  render() {
    const { data } = this.props
    const { edges: events } = data.allMarkdownRemark

    return (
      <Layout>
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="section">
                    <h1 className="title">Events</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <EventPreview events={events} />
        </section>
      </Layout>
    )
  }
}

EventsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const eventsPageQuery = graphql`
  query EventsPage {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___event_date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                gatsbyImageData(width: 120, quality: 100, layout: CONSTRAINED)
              }
            }
            templateKey
            event_date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
